import style from './css/InfoContacto.module.css'

const InfoContacto = () => {
  return (
    <div className={style.contenedor}>
        <h3 className={style.h3}>Información de contacto</h3>
        <p className={style.p}>Celular: 099669148</p>
        <p className={style.p}>Celular: 098683688</p>
        <p className={style.p}>Dirección: Ruta 11, Km 162,500, Atlantida</p>
        <p className={style.p}>Zenon, Burgueño, Soca</p>
        <p className={style.p}>Mail: potenciavial@gmail.com</p>
    </div>
  )
}

export default InfoContacto
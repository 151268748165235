import style from './css/Contacto.module.css'
import HeaderContacto from './HeaderContacto'
import FormContacto from './FormContacto'
import InfoContacto from './InfoContacto'
import shantui from '../imagenes/maquinaShantui.png'


const Contacto = () => {
  return (
    <div >
      <HeaderContacto />
      <main className={style.main}>
          {/* <div className={style.form}>
          <FormContacto  />
          </div>       */}
          <div className={style.info}>
            
          <InfoContacto/>
            </div>  
          <img src={shantui} className={style.img}></img>

      </main>
    </div>
  )
}

export default Contacto
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CartaMaquina from "../componentes/CartaMaquina";
import FiltroListado from "./FiltroListado";
import style from "./css/ListadoTipo.module.css";
import { useDispatch, useSelector } from "react-redux";
import { guardarTiposConMaquinas } from "../features/tiposSlice";

const ListadoTipo = () => {
  const { nombreTipo } = useParams();
  const url = useSelector((state) => state.global.url);
  const tipos = useSelector((state) => state.tipos.tiposConMaquinas);
  const dispatch = useDispatch();
  const [maquinasDeTipo, setMaquinasDeTipo] = useState([]);

  // Cargar tipos si aún no se han cargado
  useEffect(() => {
    if (!tipos || tipos.length === 0) {
      fetch(`${url}/maquinas/getMaquinasAndType`, {
        method: "GET",
      })
        .then((respuesta) => respuesta.json())
        .then((datos) => {
          dispatch(guardarTiposConMaquinas(datos.result.Tipos));
        })
        .catch((error) => {
          console.error('Error en la solicitud:', error);
        });
    }
  }, [dispatch, tipos, url]);

  // Actualizar maquinasDeTipo cuando tipos o nombreTipo cambian
  useEffect(() => {
    if (tipos) {
      const elTipo = tipos.find((t) => t.Nombre === nombreTipo);
      setMaquinasDeTipo(elTipo ? elTipo.Maquinas : []);
    }
  }, [tipos, nombreTipo]);

  const [filtro, setFiltro] = useState({ Nuevo: true, Usado: true });
  const [maquinasAMostrar, setMaquinasAMostrar] = useState(maquinasDeTipo);

  const handleCondicionChange = (condicion) => {
    setFiltro(condicion);
  };

  useEffect(() => {
    if (!filtro.Nuevo && !filtro.Usado) {
      setMaquinasAMostrar(maquinasDeTipo);
    } else {
      const maquinasFiltradas = maquinasDeTipo.filter((m) => {
        return (
          (filtro.Nuevo && m.Condicion === "Nuevo") ||
          (filtro.Usado && m.Condicion === "Usado")
        );
      });
      setMaquinasAMostrar(maquinasFiltradas);
    }
  }, [filtro, maquinasDeTipo]);

  if (tipos === null) {
    return <div>Cargando...</div>;
  }

  // Mover la declaración de elTipo fuera del useEffect
  const elTipo = tipos.find((t) => t.Nombre === nombreTipo);

  return (
    <>
      <div className={style.div}>
        <h2 className={style.h2}>{elTipo ? elTipo.Nombre : ""}</h2>
      </div>

      <div className={style.section}>
        <div className={style.filtro}>
          <FiltroListado onCondicionChange={handleCondicionChange} />
        </div>
        <div className={style.cards}>
          {maquinasAMostrar.length > 0 ? (
            maquinasAMostrar.map((m) => <CartaMaquina key={m.Id} {...m} />)
          ) : (
            <div className={style.noHay}>
              <strong className={style.p}>Lo sentimos...</strong>
              <p className={style.p}>
                No hay {filtro.Nuevo ? "nuevas" : "usadas"} disponibles
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ListadoTipo;
  
import MenuHeader from "./MenuHeader"
import logo from '../imagenes/logoultimo.png'
import shantui from '../imagenes/logoShantui.jpg'
import styles from './css/Header.module.css'
import { Link } from "react-router-dom"

const Header = () => {
    return (

        <header className={styles.header} >
            <Link to={'/'} className={styles.figure}>
            <figure >
                <img className={styles.img} src={logo} alt="logo" title="Logo Potencia vial"></img>
            </figure>
            </Link>
            <figure className={styles.figureShantui}>
                <img className={styles.imgShantui} src={shantui} alt="logo" title="Logo Shantui"></img>
            </figure>
            {/* <div className={styles.div}>
            <MenuHeader  />
            </div> */}
        </header>
        )
}

export default Header
import React from 'react'
import style from './css/Especificaciones.module.css'
import { useSelector } from 'react-redux';



const Especificaciones = (m) => {

  const url = useSelector(state => state.global.url)
  const Multimedia = [m.MaquinaMultimedia];
  const elPdf = Multimedia[0].filter(ima => ima.Tipo.startsWith('app'));
  const nombrePdf = elPdf.length > 0 ? elPdf[0].Nombre : null;

  return (
    <div className={style.contenedor}>
      <h3 className={style.h3}>Especificaciones</h3>
      
      {nombrePdf && (
        <a
          className={style.pdf}
          href={`${url}/${nombrePdf}`} // Asegúrate de proporcionar la URL correcta
          download
          target='_blank'
        >
          Ver ficha completa
        </a>
      )}
      <table className={style.table}>
        <tbody>
          <tr className={style.tr}>
            <th className={style.th}>Marca</th>
            <td className={style.td}>{m.Marca}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Modelo</th>
            <td className={style.td}>{m.Modelo}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Año</th>
            <td className={style.td}>{m.Anio}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Condición</th>
            <td className={style.td}>{m.Condicion}</td>
          </tr>
          {m.CargaMaxima && (
            <tr className={style.tr}>
              <th className={style.th}>Carga máxima</th>
              <td className={style.td}>{m.CargaMaxima}</td>
            </tr>
          )}
          {m.Altura && (
            <tr className={style.tr}>
              <th className={style.th}>Altura</th>
              <td className={style.td}>{m.Altura}</td>
            </tr>
          )}
          {m.Largo && (
            <tr className={style.tr}>
              <th className={style.th}>Largo</th>
              <td className={style.td}>{m.Largo}</td>
            </tr>
          )}
          {m.Ancho && (
            <tr className={style.tr}>
              <th className={style.th}>Ancho</th>
              <td className={style.td}>{m.Ancho}</td>
            </tr>
          )}
          {m.Descripcion && (
            <tr className={style.tr}>
              <th className={style.th}>Descripción</th>
              <td className={style.td}>{m.Descripcion}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Especificaciones
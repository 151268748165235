import React, { useState } from 'react';
import style from './css/FotoMaquina.module.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSelector } from 'react-redux';

const FotoMaquina = (m) => {
  const url = useSelector(state => state.global.url);

  const tipos = useSelector(state => state.tipos.tiposConMaquinas);
  const elTipo = tipos.find(t => t.Id === m.TipoId);
  const Multimedia = [m.MaquinaMultimedia];
  const imagenes = Multimedia[0].filter(ima => ima.Tipo.startsWith('ima'));
  const nombres = imagenes.map(i => i.Nombre);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const openModal = (index) => {
    setModalIndex(index);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const navigateImage = (direction) => {
    // Lógica para navegar a la imagen siguiente o anterior en la modal
    if (direction === 'next') {
      setModalIndex((prevIndex) => (prevIndex + 1) % nombres.length);
    } else if (direction === 'previous') {
      setModalIndex((prevIndex) => (prevIndex - 1 + nombres.length) % nombres.length);
    }
  };

  if (nombres.length === 0) {
    return (
      <div>
        {elTipo && <h5 className={style.h5}>{elTipo.Nombre}</h5>}
        <h2 className={style.h2}>{m.Modelo}</h2>
        <div className={style.div}>
          <p className={style.p}>No hay imágenes</p>
        </div>
      </div>
    );
  }

  return (
    <div>
        {elTipo && <h5 className={style.h5}>{elTipo.Nombre}</h5>}
      <h2 className={style.h2}>{m.Modelo}</h2>
      <Carousel showThumbs={false}>
        {nombres.map((u, index) => (
          <div key={index} className={style.div} onClick={() => openModal(index)}>
            <img className={style.img} src={`${url}/${u}`} alt={`Imagen ${index}`} />
          </div>
        ))}
      </Carousel>
      {modalVisible && (
        <div className={`${style.overlay} active`}>
          <div className={style.modal}>
            <span className={style.closeButton} onClick={closeModal}>&times;</span>
            <img
              className={style.modalContent}
              src={`${url}/${nombres[modalIndex]}`}
              alt={`Imagen ${modalIndex}`}
            />
            <button
              className={`${style.navigationButton} ${style.previousButton}`}
              onClick={() => navigateImage('previous')}
            >
              &larr; {/* Flecha hacia la izquierda */}

            </button>
            <button
              className={`${style.navigationButton} ${style.nextButton}`}
              onClick={() => navigateImage('next')}
            >
              &rarr; {/* Flecha hacia la derecha */}

            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FotoMaquina;

import React from 'react'
import shantui from "../imagenes/maquinaShantui.png"
import style from './css/FotoVender.module.css'

const FotoVender = () => {
  return (
    <figure className={style.figure}>
      <img className={style.img} src={shantui}></img>
    </figure>
  )
}

export default FotoVender
import style from './css/ContactoVender.module.css'
import { Link } from 'react-router-dom'


const ContactoVender = () => {
    return (
        <div className={style.contenedor}>
            <h3 className={style.h}> ¿Estás buscando vender tu maquina?</h3>
            <p className={style.p}>Nosotros te ayudamos</p>
            <Link to={'/contacto'} className={style.button}>Contactanos</Link>
        </div>
    )
}

export default ContactoVender
import Categorias from "./Categorias";
import Multimedia from "./Multimedia";
import style from './css/Principal.module.css'



export const Principal = () => {
    
    return (
        <section className={style.section}>
            <h1 className={style.h1}>MAQUINARIA</h1>
            <p className={style.p}>Nueva y Usada</p>
            <Categorias />
        </section>
    )
}

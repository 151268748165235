import { useSelector } from 'react-redux';
import style from './css/ListadoMaquina.module.css'
import { Link } from 'react-router-dom'

const ListadoMaquinas = ({maquinas}) => {

    const tipos = useSelector(state=>state.tipos.tipos)


    // Función para obtener el nombre del tipo por TipoId
    const getNombreTipo = (tipoId) => {
        const tipo = tipos.find(t => t.Id === tipoId);
        return tipo ? tipo.Nombre : 'Tipo Desconocido';
    };

    return (
        <div className={style.contenedor}>
            <div className={style.scrolleable}>

            <table className={style.table}>
            <thead className={style.thead}>
                <tr>
                    <th className={style.th}>Marca</th>
                    <th className={style.th}>Modelo</th>
                    <th className={style.th}>Tipo</th>
                    <th className={style.th}>Año</th>
                </tr>
            </thead >
            <tbody className={style.tbody}>
                {maquinas.map(m=>
                    
                <tr key={m.Id}>
                    <td className={style.td}>{m.marca}</td>
                    <td className={style.td}>{m.Modelo}</td>
                    <td className={style.td}>{getNombreTipo(m.TipoId)}</td>
                    <td className={style.td}>{m.anio}</td>
                    <td><Link className={style.button} to={`/maquinaadministrador/${m.Id}`}>Ver</Link></td>
                </tr>
                    )}
                
            </tbody>
        </table>
            </div>
        </div>
    )
}

export default ListadoMaquinas
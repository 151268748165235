import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import style from './css/Agregar.module.css'
import { async } from 'q'
import FotoMaquina from './FotoMaquina'
import { Link } from 'react-router-dom'

const Agregar = () => {

    const tipos = useSelector(state => state.tipos.tipos)
    const anioActual = new Date().getFullYear();
    const url = useSelector(state => state.global.url)


    const tipoCampo = useRef(null)
    const marcaCampo = useRef(null)
    const modeloCampo = useRef(null)
    const anioCampo = useRef(null)
    const descripcionCampo = useRef(null)
    const condicionCampo = useRef(null)
    const altoCampo = useRef(null)
    const anchoCampo = useRef(null)
    const largoCampo = useRef(null)
    const cargaCampo = useRef(null)
    const precioCampo = useRef(null)

    const agregarMaquina = async (event) => {
        event.preventDefault()

        let tipo = tipoCampo.current.value
        let modelo = modeloCampo.current.value
        let marca = marcaCampo.current.value
        let anio = anioCampo.current.value
        let descripcion = descripcionCampo.current.value
        let condicion = condicionCampo.current.value
        let largo = largoCampo.current.value
        let alto = altoCampo.current.value
        let ancho = anchoCampo.current.value
        let carga = cargaCampo.current.value
        let precio = precioCampo.current.value


        //Llamara a la api

        if (tipo.trim() == "Seleccionar" || condicion.trim() == "Seleccionar" || modelo.trim() == "" || anio.trim() == "" || marca.trim() == "") {
            alert("Rellena los campos obligatorios.");
            return;
        }

        if (anio > anioActual + 1 || anio < 1950) {
            alert("El año ingresado esta fuera del rango aceptado")
            return;
        }

        if (modelo.length > 25){
            alert('El modelo no puede sobrepasar los 25 caracteres')
            return;
        }

        try {

            const data = {
                "Modelo": modelo,
                "TipoId": tipo,
                "Condicion": condicion,
                "Altura": alto,
                "Largo": largo,
                "Ancho": ancho,
                "CargaMaxima": carga,
                "descripcion": descripcion,
                "anio": anio,
                "marca": marca,
                "Precio": precio
            }


            const headers = {
                "Content-Type": "application/json"

            }
            const response = await fetch(`${url}/maquinas/addMaquina`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            })
            if (response.status === 200) {
                alert("Maquina agregada con éxito");
            } else {
                alert("Error al subir la maquina");
            }

            event.target.reset()

        } catch (error) {
            console.log('Error al agregar' + error);
        }

    }
    
    return (
        <div className={style.contenedor}>
            <div className={style.linea}>
                <h3 className={style.h3}>Agregar Maquina</h3>
            </div>
            <form className={style.form} onSubmit={agregarMaquina}>
                <Link className={style.link} to={'/homeAdmin'}>Volver al listado</Link>

                <div className={style.item}>
                    <label htmlFor="marcaAgregar" className={style.label}>*Marca: </label>
                    <input type="text" className={style.input} id="marcaAgregar" ref={marcaCampo} required />
                </div>
                <div className={style.item}>
                    <label htmlFor="modeloAgregar" className={style.label}>*Modelo: </label>
                    <input type="text" className={style.input} id="modeloAgregar" ref={modeloCampo} required />
                </div>
                <div className={style.item} >
                    <label className={style.label} htmlFor="tipoAgregar">*Tipo: </label>
                    <select className={style.select} id="tipoAgregar" required ref={tipoCampo}>
                        <option hidden defaultValue={""}>Seleccionar</option>
                        {tipos.map(t => <option key={t.Id} value={t.Id}>{t.Nombre}</option>)}
                    </select>
                </div>

                <div className={style.item}>
                    <label htmlFor="anioAgregar" className={style.label}>*Año: </label>
                    <input type="text" className={style.input} id="anioAgregar" ref={anioCampo} required />
                </div>
                <div className={style.item}>
                    <label className={style.label} htmlFor="condicionAgregar">*Condicion: </label>
                    <select required className={style.select} id="condicionAgregar" ref={condicionCampo}>
                        <option hidden defaultValue={""}>Seleccionar</option>
                        <option value='Nuevo'>Nuevo</option>
                        <option value='Usado'>Usado</option>
                    </select>
                </div>
                <div className={style.item}>
                    <label htmlFor="precioAgregar" className={style.label}>Precio: </label>
                    <input type="text" className={style.input} id="precioAgregar" ref={precioCampo} />
                </div>
                <div className={style.item}>
                    <label htmlFor="anioAgregar" className={style.label}>Largo: </label>
                    <input type="text" className={style.input} id="anioAgregar" ref={largoCampo} />
                </div>
                <div className={style.item}>
                    <label htmlFor="anioAgregar" className={style.label}>Ancho: </label>
                    <input type="text" className={style.input} id="anioAgregar" ref={anchoCampo} />
                </div>
                <div className={style.item}>
                    <label htmlFor="anioAgregar" className={style.label}>Alto: </label>
                    <input type="text" className={style.input} id="anioAgregar" ref={altoCampo} />
                </div>
                <div className={style.item}>
                    <label htmlFor="anioAgregar" className={style.label}>Carga maxima: </label>
                    <input type="text" className={style.input} id="anioAgregar" ref={cargaCampo} />
                </div>
                <div className={style.item}>
                    <label htmlFor="descripcionAgregar" className={style.label}>Descripcion: </label>
                    <input type="text" className={style.input} id="descripcionAgregar" ref={descripcionCampo} />
                </div>
                <button className={style.button} type="submit" >Agregar</button>
            </form>

        </div>
    )
}

export default Agregar
import { useEffect, useRef, useState } from "react"
import style from './css/HomeAdmin.module.css'
import ListadoMaquinas from './ListadoMaquinas'
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { guardarMaquinas } from "../features/maquinasSlice"
import { guardarTipos } from "../features/tiposSlice"

const HomeAdmin = () => {
  const url = useSelector(state => state.global.url)
  const navigate = useNavigate();
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem("apiKey") === null) {
      navigate("/");}
    fetch(`${url}/tipos/getTipos`, {
      method: "GET",
    })
      .then((respuesta) => respuesta.json())
      .then((datos) => {
        dispatch(guardarTipos(datos));
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("apiKey") === null) {
      navigate("/");}
    fetch(`${url}/maquinas/getMaquinas`, {
      method: "GET",
    }).then(respuesta => respuesta.json())
      .then(datos => {
        dispatch(guardarMaquinas(datos))

      }).catch(error => {
        console.error('Error en la solicitud:', error);
      });
  }, [])

 

  const tipos = useSelector(state => state.tipos.tipos)
  const maquinas = useSelector(state => state.maquinas.maquinas)

  const nombreCampo = useRef(null)
  const tipoCampo = useRef(null)
  const [maquinasFiltradas, setMaquinasFiltradas] = useState(maquinas);


  const BuscarTipo = () => {
    
    let tipoActual = tipoCampo.current.value;
    if(tipoActual ==="Todas"){
      setMaquinasFiltradas(maquinas)
    }else{

      const maquinasFiltradasPorTipo = maquinas.filter(m => m.TipoId == tipoActual);
      setMaquinasFiltradas(maquinasFiltradasPorTipo);
    }

  }

  const BuscarModelo = () => {
    let nombreActual = nombreCampo.current.value;
    const maquinasFiltradasPorNombre = maquinas.filter(m => m.Modelo.toLowerCase().startsWith(nombreActual.toLowerCase()));
    setMaquinasFiltradas(maquinasFiltradasPorNombre);

  }

  return (
    <div className={style.contenedor}>
      <div className={style.linea}>
        <h3 className={style.h3}>Administrador</h3>
      </div>
      <div className={style.main}>

        <div className={style.buscar}>

          <label className={style.label} htmlFor="buscarModelo">Buscar por modelo:</label>
          <input className={style.input} type="text" id="buscarModelo" ref={nombreCampo}></input>
          <input className={style.button} type="button" value='Buscar' onClick={BuscarModelo}></input>
        </div>

        <div className={style.buscar}>

          <label className={style.label} htmlFor="buscarTipo">Buscar por tipo: </label>
          <select className={style.select} id="buscarTipo" ref={tipoCampo}>
            <option  defaultValue={""}>Todas</option>
            {tipos.map(t => <option key={t.Id} value={t.Id}>{t.Nombre}</option>)}
          </select>
          <input className={style.button} type="button" value='Buscar' onClick={BuscarTipo}></input>

        </div>
        <Link className={style.agregar} to={'/Agregar'}>Agregar</Link>
        <ListadoMaquinas maquinas={maquinasFiltradas} />
      </div>

    </div>
  )
}

export default HomeAdmin
import './App.css';
import './bootstrap.min.css';
import Administrador from './componentes/Administrador';
import Contacto from './componentes/Contacto';
import Contenedor from './componentes/Contenedor';
import Home from './componentes/Home';
import ListadoTipo from './componentes/ListadoTipo';
import Maquina from './componentes/Maquina';
import HomeAdmin from './componentes/HomeAdmin';
import Agregar from './componentes/Agregar'
import { store } from './store/store';
import { Provider } from 'react-redux';
import { HashRouter, Routes, Route } from 'react-router-dom';
import MaquinaAdministrador from './componentes/MaquinaAdministrador';
import ScrollToTop from './componentes/ScrollTop';

function App() {
    return(
    <Provider store={store} >
        <HashRouter>
        <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Contenedor />}>

                    <Route path='/' element={<Home/>}/>
                    <Route path='/listadotipo/:nombreTipo' element={<ListadoTipo/>}/>
                    <Route path='/maquina/:idMaquina' element={<Maquina/>}/>
                    <Route path='/contacto' element={<Contacto/>}/>
                    <Route path="/administrador" element={<Administrador/>} /> 
                    <Route path="/homeAdmin" element={<HomeAdmin/>} /> 
                    <Route path="/agregar" element={<Agregar/>} /> 
                    <Route path="/maquinaadministrador/:idMaquina" element={<MaquinaAdministrador/>} /> 

                </Route>

            </Routes>
        </HashRouter>
    </Provider>
    )
}
export default App;

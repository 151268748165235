import { useState } from 'react';
import style from './css/FiltroListado.module.css'


const FiltroListado = ({onCondicionChange}) => {

    const [Nuevo, setNuevo] = useState(false);
    const [Usado, setUsado] = useState(false);

    const handleNuevoChange = () => {
        setNuevo(!Nuevo);
        onCondicionChange({ Nuevo: !Nuevo, Usado });
    };

    const handleUsadoChange = () => {
        setUsado(!Usado);
        onCondicionChange({ Nuevo, Usado: !Usado });
    };


    return (
        <div className={style.card}>
            <h3 className={style.titulo}>Filtro</h3>
            <div className={style.item}>
                <label className={style.label}  htmlFor="nuevo">Nuevo</label>
                <input className={style.input} type="checkbox" id="nuevo" checked={Nuevo} onChange={handleNuevoChange}/>

                <label className={style.label}  htmlFor="usado">Usado</label>
                <input className={style.input} type="checkbox" id="usado" checked={Usado} onChange={handleUsadoChange} />
            </div>
           
        </div>

    )
}

export default FiltroListado
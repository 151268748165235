import style from './css/AsideMaquina.module.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AsideMaquina = (m) => {

  const tipos = useSelector(state => state.tipos.tiposConMaquinas)
  const url = useSelector(state => state.global.url)
  const Multimedia = [m.MaquinaMultimedia];
  const elTipo = tipos.find(t => t.Id === m.TipoId)
  const elPdf = Multimedia[0].filter(ima => ima.Tipo.startsWith('app'));
  const nombrePdf = elPdf.length > 0 ? elPdf[0].Nombre : null;
  console.log(m.Precio);

  if (elTipo === undefined) {
    return (
      <div className={style.contenedor}>
        <h2 className={style.h2}>{m.Modelo}</h2>
        <p className={style.p}>Marca: {m.Marca}</p>
        <p className={style.p}>Condición: {m.Condicion}</p>
        <p className={style.p}>Año: {m.Anio}</p>
        {/* Mostrar el precio si está definido */}
        {m.Precio != null && (
          <p className={style.precio}>${m.Precio}</p>
        )}

        {/* Mostrar el botón de cotización solo si no hay precio */}
        {m.Precio == null && (
          <Link className={style.button} to={'/contacto'}>Solicitar cotización</Link>
        )}
        {nombrePdf && (
          <a className={style.buttonFicha} href={`${url}/${nombrePdf}`} download target='_blank'>Ver ficha completa</a>
        )}
      </div>
    )
  }
  return (
    <div className={style.contenedor}>
      <h5 className={style.h5}>{elTipo.Nombre}</h5>
      <h2 className={style.h2}>{m.Modelo}</h2>
      <p className={style.p}>Marca: {m.Marca}</p>
      <p className={style.p}>Condición: {m.Condicion}</p>
      <p className={style.p}>Año: {m.Anio}</p>
      {/* Mostrar el precio si está definido */}
      {m.Precio != null && (
        <p className={style.precio}>$ {m.Precio}</p>
      )}

      {/* Mostrar el botón de cotización solo si no hay precio */}
      {m.Precio == null && (
        <Link className={style.button} to={'/contacto'}> Solicitar cotización</Link>
      )}
      {nombrePdf && (
        <a className={style.buttonFicha} href={`${url}/${nombrePdf}`} download target='_blank'>Ver ficha completa</a>
      )}
    </div>
  )
}

export default AsideMaquina
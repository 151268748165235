import style from "./css/Administrador.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { guardarTipos } from "../features/tiposSlice";
import { guardarToken } from "../features/globalSlice";

const Administrador = () => {
  const url = useSelector((state) => state.global.url);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const user = useRef(null);
  const pass = useRef(null);

  useEffect(() => {
    fetch(`${url}/tipos/getTipos`, {
      method: "GET",
    })
      .then((respuesta) => respuesta.json())
      .then((datos) => {
        dispatch(guardarTipos(datos));
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      });
  }, []);

  const ingresar = async (event) => {
    event.preventDefault();

    const userCampo = user.current.value;
    const passCampo = pass.current.value;

    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      username: userCampo,
      password: passCampo,
    };

    // if (userCampo == "a") {
    //   navigate("/homeAdmin");
    // }

    try {
      const response = await fetch(`${url}/login/validarUsuario`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      const respuesta = await response.json();
      if (respuesta.resultado.statusCode == 200) {
        dispatch(guardarToken(respuesta.user.token));
        localStorage.setItem("apiKey", respuesta.user.token);
        //localStorage.setItem("id", datos.id);
        navigate("/homeAdmin");
      } else {
        localStorage.clear()
        setError("Datos incorrectos");
      }

      event.target.reset();
    } catch (error) {
      setError(error);
      console.log("error en la solicitud" + error);
    }
  };

  return (
    <div className={style.contenedor}>
      <h2>Login</h2>

      <form className={style.form} onSubmit={ingresar}>
        <div className={style.div}>
          <label htmlFor="usuario" className="form-label">
            Usuario:{" "}
          </label>
          <input type="text" className="form-control" id="usuario" ref={user} />
        </div>
        <div className={style.div}>
          <label htmlFor="pass" className="form-label">
            Contraseña:{" "}
          </label>
          <input
            type="password"
            className="form-control"
            id="pass"
            ref={pass}
          />
        </div>
      {error && <p className={style.error}>{error}</p>}
        <button className={style.button} type="submit">
          Ingresar
        </button>
      </form>
    </div>
  );
};

export default Administrador;

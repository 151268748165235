import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //url: "http://localhost:3000",
  url: "https://nodepotenciavial.potenciavial.com.uy"
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    guardarToken: (state, action) => {

      state.token = action.payload;
    },
    eliminarMaquina: (state) => {
      state.token = "NO";
    },
  },
});
export const { guardarToken } = globalSlice.actions;

export default globalSlice.reducer;

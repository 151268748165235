import { useSelector } from 'react-redux'
import style from './css/Busqueda.module.css'
import { useRef } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const Busqueda = () => {

    const tipos = useSelector(state => state.tipos.tiposConMaquinas)
    let navigate = useNavigate()

    const cat = useRef(null)
  

    const Buscar = () => {
        const catCampo = cat.current.value;
        console.log(catCampo);
        if (catCampo !="Categorias"){

            navigate(`ListadoTipo/${catCampo}`)
        }
    }

    return (
        <section className={style.section}>
            <form className={style.form}>
                <label className={style.label} htmlFor="categorias"></label>
                <select className={style.select} id="categorias" ref={cat}>
                    <option hidden defaultValue={""}>Categorias</option>
                    {tipos.map(t => <option key={t.Id} value={t.Nombre}>{t.Nombre}</option>)}
                </select>
            </form>
            <input className={style.button} value='Buscar' type='button' onClick={Buscar}/>
        </section>
    )
}

export default Busqueda
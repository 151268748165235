import FotoMaquina from './FotoMaquina'
import AsideMaquina from './AsideMaquina'
import Especificaciones from './Especificaciones'
import style from './css/Maquina.module.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const Maquina = () => {

    const { idMaquina } = useParams()

    const [maquina, setMaquina] = useState(null)

    useEffect(() => {
        fetch(`https://nodepotenciavial.potenciavial.com.uy/maquinas/getMaquina/${idMaquina}`, {
            method: "GET",
        }).then(respuesta => respuesta.json())
            .then(datos => {
                if(datos!=null){
                    setMaquina(datos)

                }

            }).catch(error => {
                console.error('Error en la solicitud:', error);
            });


    }, [idMaquina])


    if (maquina ==null) {
        return <div>Cargando...</div>;
    }


    return (
        <>

            <div className={style.divLinea}>
                <h3 className={style.p}> {maquina.Modelo}</h3>
            </div>
            <div className={style.contenedor}>
                <div className={style.foto}>
                    <FotoMaquina {...maquina} />
                </div>
                <div className={style.aside}>

                    <AsideMaquina {...maquina} />
                </div>
                <div className={style.especificaciones}>
                    <Especificaciones {...maquina} />

                </div>
            </div>
        </>
    )

}

export default Maquina
import React, { useState } from "react";
import style from './css/Multimedia.module.css'
import { useSelector } from "react-redux";

function Multimedia(props) {
  const url = useSelector(state => state.global.url)

  const { maquinaId } = props;
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      alert("Selecciona una imagen primero.");
      return;
    }
   
    // const headers = {
      
    //   'Authorization': `Bearer ${token}`,
    // }


    // Crea un objeto FormData para enviar la imagen al servidor
    const formData = new FormData();
    formData.append("MaquinaId", maquinaId)
    formData.append("image", selectedImage);

    try {
      // Realiza la solicitud POST utilizando fetch
      const response = await fetch(`${url}/upload/upload`, {
        method: "POST",
        //headers: headers,
        body: formData,
      });

      if (response.status === 200) {
        alert("Imagen subida con éxito");
      } else {
        console.log('response: ' + response.json);
        alert("Error al subir la imagen");
      }
    } catch (error) {
      console.error(error);
      alert("Error de servidor");
    }
  };

  return (
    <div className={style.contenedor}>
      <input className={style.input} type="file" accept="image/*" name='image' onChange={handleImageChange} />
      <button className={style.button} onClick={handleImageUpload}>Subir Imagen</button>
    </div>
  );
}

export default Multimedia;

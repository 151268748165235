import { Outlet } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"
import style from './css/Contenedor.module.css'


const Contenedor = () => {
    return (
        <div className={style.body}>
        <div className={style.contenedor}>

            <Header />
            <main>
                <Outlet />
            </main>
        </div>
            <Footer />
        </div>
        )
}

export default Contenedor
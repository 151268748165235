import style from "./css/CartaMaquina.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import imagenPorDefecto from "../imagenes/fotoCards.jpeg"

const CartaMaquina = (m) => {
  const url = useSelector((state) => state.global.url);

  const Multimedia = [m.MaquinaMultimedia];

  const imagenes = Multimedia[0].filter((ima) => ima.Tipo.startsWith("ima"));
  if (imagenes.length>0) {
  const laImagen = imagenes[0].Nombre;

  return (
    <>
      <div className={style.card}>
        <div className={style.contenido}>
          <h2 className={style.titulo}>{m.Modelo}</h2>
          <p className={style.descripcion}><strong>Marca: </strong> {m.Marca}</p>
          <p className={style.descripcion}><strong>Condición: </strong> {m.Condicion}</p>
           {/* Mostrar el precio si está definido */}
      {m.Precio != null && (
        <p className={style.precio}>${m.Precio}</p>
      )}

      {/* Mostrar el botón de cotización solo si no hay precio */}
      {m.Precio == null && (
        <p className={style.descripcion}><strong>Año: </strong>{m.Anio}</p>
      )}
         
          <Link to={`/Maquina/${m.Id}`} className={style.button}>
            Ver
          </Link>
        </div>
        <div  className={style.divImagen}>
        <img className={style.img} src={`${url}/${laImagen}`} alt="Imagen" />
        </div>
      </div>
    </>
  );
} else {

  return (
    <>
      <div className={style.card}>
        <div className={style.contenido}>
          <h2 className={style.titulo}>{m.Modelo}</h2>
          <p className={style.descripcion}><strong>Marca: </strong> {m.Marca}</p>
          <p className={style.descripcion}><strong>Condición: </strong> {m.Condicion}</p>
 {/* Mostrar el precio si está definido */}
 {m.Precio != null && (
        <p className={style.precio}>${m.Precio}</p>
      )}

      {/* Mostrar el botón de cotización solo si no hay precio */}
      {m.Precio == null && (
        <p className={style.descripcion}><strong>Año: </strong>{m.Anio}</p>
      )}
                   <Link to={`/Maquina/${m.Id}`} className={style.button}>
            Ver
          </Link>
        </div>
        <div className={style.divImagen}>

        <img className={style.img} src={imagenPorDefecto} alt="Imagen" />
        </div>
      </div>
    </>
  );
}
};

export default CartaMaquina;



import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import style from './css/MaquinaAdministrador.module.css'
import Multimedia from './Multimedia';
import MultimediaPdf from './MultimediaPdf';
import { guardarTipos } from '../features/tiposSlice';
import { guardarMaquinas } from '../features/maquinasSlice';


const MaquinaAdministrador = () => {

  const { idMaquina } = useParams();
  const url = useSelector(state => state.global.url)

  const navigate = useNavigate();
  const dispatch = useDispatch()
  
  useEffect(() => {
    
    if (localStorage.getItem("apiKey") === null) {
      navigate("/");
    }
      fetch(`${url}/maquinas/getMaquinas`, {
        method: "GET",
      }).then(respuesta => respuesta.json())
        .then(datos => {
          dispatch(guardarMaquinas(datos))
  
        }).catch(error => {
          console.error('Error en la solicitud:', error);
        });
    }, [])

  useEffect(() => {
    if (localStorage.getItem("apiKey") === null) {
      navigate("/");
    }
    fetch(`${url}/tipos/getTipos`, {
      method: "GET",
    })
      .then((respuesta) => respuesta.json())
      .then((datos) => {
        dispatch(guardarTipos(datos));
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      });
  }, []);


  const maquinas = useSelector(state => state.maquinas.maquinas)
  const tipos = useSelector(state => state.tipos.tipos)
  const m = maquinas.find(maq => maq.Id == idMaquina)
  
  const getNombreTipo = (tipoId) => {
    const tipo = tipos.find(t => t.Id === tipoId);
    return tipo ? tipo.Nombre : 'Tipo Desconocido';
  };

  const handleEliminar = async (event) => {
    event.preventDefault()


    try {

      const data = {
        "Id": m.Id,

      }

      const headers = {
        "Content-Type": "application/json",
      }
      const response = await fetch(`${url}/maquinas/deleteMaquina`, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(data)
      })
      if (response.status === 200) {
        alert("Maquina eliminada con éxito");
      } else {
        alert("Error al eliminar la maquina");
      }

      event.target.reset()

    } catch (error) {
      console.log('Error al agregar' + error);
    }

    navigate('/homeAdmin');
  };

  if (!m) {
    return <div>Cargando...</div>;
  }


  return (
    <div className={style.contenedor}>
      <div className={style.linea}>
        <h3 className={style.h3}>Especificaciones</h3>
      </div>
      <table className={style.table}>
        <tbody>
          <tr className={style.tr}>
            <th className={style.th}>Marca</th>
            <td className={style.td}>{m.marca}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Modelo</th>
            <td className={style.td}>{m.Modelo}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Tipo</th>
            <td className={style.td}>{getNombreTipo(m.TipoId)}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Año</th>
            <td className={style.td}>{m.anio}</td>
          </tr>
          <tr className={style.tr}>
            <th className={style.th}>Condicion</th>
            <td className={style.td}>{m.Condicion}</td>
          </tr>
          {m.Precio && (
            <tr className={style.tr}>
              <th className={style.th}>Precio</th>
              <td className={style.td}>{m.Precio}</td>
            </tr>
          )}
          {m.CargaMaxima && (
            <tr className={style.tr}>
              <th className={style.th}>Carga máxima</th>
              <td className={style.td}>{m.CargaMaxima}kg</td>
            </tr>
          )}
          {m.Altura && (
            <tr className={style.tr}>
              <th className={style.th}>Altura</th>
              <td className={style.td}>{m.Altura}cm</td>
            </tr>
          )}
          {m.Largo && (
            <tr className={style.tr}>
              <th className={style.th}>Largo</th>
              <td className={style.td}>{m.Largo}cm</td>
            </tr>
          )}
          {m.Ancho && (
            <tr className={style.tr}>
              <th className={style.th}>Ancho</th>
              <td className={style.td}>{m.Ancho}cm</td>
            </tr>
          )}
          {m.descripcion && (
            <tr className={style.tr}>
              <th className={style.th}>Descripción</th>
              <td className={style.td}>{m.descripcion}</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className={style.botones}>

        <button className={style.button} onClick={handleEliminar}>Eliminar Máquina</button>
        <Multimedia className={style.multimedia} maquinaId={idMaquina} ></Multimedia>
        <MultimediaPdf className={style.multimedia} maquinaId={idMaquina} ></MultimediaPdf>
        <Link className={style.link} to="/homeAdmin">Volver al listado</Link>
      </div>
    </div>
  );
};

export default MaquinaAdministrador
import style from './css/Categorias.module.css'
import imgBulldozer from '../imagenes/categorias/bulldozer.png'
import imgCargadorFrontalOruga from '../imagenes/categorias/cargadoresFrontalesOruga.png'
import imgExcavadoraOruga from '../imagenes/categorias/excavadorasOrugas.png'
import imgCargadorFrontalNeu from '../imagenes/categorias/cargadoresFrontalesNeumaticos.png'
import imgRetroexcavadora from '../imagenes/categorias/retroexcavadoras.png'
import imgMotoniveladores from '../imagenes/categorias/motoniveladoras.png'
import imgCompactadores from '../imagenes/categorias/compactadores.png'
import imgRegadorasAsfalto from '../imagenes/categorias/regadoresAsfalto.png'
import imgTerminadorasAsfalto from '../imagenes/categorias/terminadoraAsfalto.png'
import imgBarredoras from '../imagenes/categorias/barredoraVial.png'
import imgGravilladoras from '../imagenes/categorias/gravilladoras.png'
import imgTrituuradoras from '../imagenes/categorias/trituradoras.png'
import imgPlantasHormigon from '../imagenes/categorias/plantasHormigon.png'
import imgLavaderoAridos from '../imagenes/categorias/lavadoresAridos.png'
import imgCamionVolcadora from '../imagenes/categorias/camionVolcadora.png'
import imgCompresores from '../imagenes/categorias/compresor.png'
import imgGeneradores from '../imagenes/categorias/generador.png'
import imgMinicargadores from '../imagenes/categorias/minicargadores.png'
import imgGruas from '../imagenes/categorias/grua.png'
import imgAccesorios from '../imagenes/categorias/accesorios.png'
import imgAutoelevadores from '../imagenes/categorias/autoelevadores.png'
import { Link } from 'react-router-dom'


const Categorias = () => {


    return (
            <div className={style.contenedor}>

                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Bulldozer'}`} className={style.a}>
                        <figure className={style.figure}><img className={style.img} src={imgBulldozer}></img></figure>
                        <span>Bulldozers</span>
                    </Link>
                </article>

                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Cargadores frontales sobre orugas'}`} className={style.a} >
                        <figure><img className={style.img} src={imgCargadorFrontalOruga}></img></figure>
                        <span>Cargadores frontales sobre oruga</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Excavadoras sobre orugas'}`} className={style.a}>
                        <figure><img className={style.img} src={imgExcavadoraOruga}></img></figure>
                        <span>Excavadoras sobre oruga</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Cargadores frontales sobre neumáticos'}`} className={style.a}>
                        <figure><img className={style.img} src={imgCargadorFrontalNeu}></img></figure>
                        <span>Cargadores frontales sobre neumáticos</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Retroexcavadoras combinadas'}`} className={style.a}>
                        <figure><img className={style.img} src={imgRetroexcavadora}></img></figure>
                        <span>Retroexcavadoras combinadas</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Motoniveladoras'}`} className={style.a}>
                        <figure><img className={style.img} src={imgMotoniveladores}></img></figure>
                        <span>Motoniveladoras</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Compactadores'}`} className={style.a}>
                        <figure><img className={style.img} src={imgCompactadores}></img></figure>
                        <span>Compactadores</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Regadores de asfalto'}`} className={style.a}>
                        <figure><img className={style.img} src={imgRegadorasAsfalto}></img></figure>
                        <span>Regadores de asfalto</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Terminadoras de asfalto'}`} className={style.a}>
                        <figure><img className={style.img} src={imgTerminadorasAsfalto}></img></figure>
                        <span>Terminadoras de asfalto</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Barredores viales'}`} className={style.a}>
                        <figure><img className={style.img} src={imgBarredoras}></img></figure>
                        <span>Barredores viales</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Gravilladoras'}`} className={style.a}>
                        <figure><img className={style.img} src={imgGravilladoras}></img></figure>
                        <span>Gravilladoras</span>
                    </Link>
                </article>

                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Trituradoras'}`} className={style.a}>
                        <figure><img className={style.img} src={imgTrituuradoras}></img></figure>
                        <span>Trituradoras</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Plantas de hormigón y asfalto'}`} className={style.a}>
                        <figure><img className={style.img} src={imgPlantasHormigon}></img></figure>
                        <span>Plantas de hormigón/ Asfalto</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link to={`/ListadoTipo/${'Lavaderos de áridos'}`} className={style.a}>
                        <figure><img className={style.img} src={imgLavaderoAridos}></img></figure>
                        <span>Lavadero de áridos</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Camiones con volcadora'}`}>
                        <figure><img className={style.img} src={imgCamionVolcadora}></img></figure>
                        <span>Camiones con volcadora</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Compresores'}`}>
                        <figure><img className={style.img} src={imgCompresores}></img></figure>
                        <span>Compresores</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Generadores'}`}>
                        <figure><img className={style.img} src={imgGeneradores}></img></figure>
                        <span>Generadores</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Grúas'}`}>
                        <figure><img className={style.img} src={imgGruas}></img></figure>
                        <span>Grúas</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Autoelevadores'}`}>
                        <figure><img className={style.img} src={imgAutoelevadores}></img></figure>
                        <span>Autoelevadores</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Minicargadores'}`}>
                        <figure><img className={style.img} src={imgMinicargadores}></img></figure>
                        <span>Minicargadores</span>
                    </Link>
                </article>
                <article className={style.article}>
                    <Link className={style.a} to={`/ListadoTipo/${'Accesorios'}`}>
                        <figure><img className={style.img} src={imgAccesorios} ></img></figure>
                        <span>Accesorios </span>
                    </Link>
                </article>

            </div>

    )
}

export default Categorias

import React, { useState } from "react";
import style from './css/Multimedia.module.css'
import { useSelector } from "react-redux";

function MultimediaPdf(props) {
  const url = useSelector(state => state.global.url)

  const { maquinaId } = props;
  const [selectedFile, setSelectedFile] = useState(null);

  const handlePDFChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      alert("Selecciona un archivo PDF válido.");
    }  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Selecciona un pdf primero.");
      return;
    }

    // Crea un objeto FormData para enviar la imagen al servidor
    const formData = new FormData();
    formData.append("MaquinaId", maquinaId)
    formData.append("image", selectedFile);

    try {
      // Realiza la solicitud POST utilizando fetch
      const response = await fetch(`${url}/upload/upload`, {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        alert("PDF subido con éxito");
      } else {
        alert("Error al subir el PDF");
      }
    } catch (error) {
      console.error(error);
      alert("Error al subir el PDF");
    }
  };

  return (
    <div className={style.contenedor}>
      <input className={style.input} type="file" accept=".pdf" onChange={handlePDFChange} />
      <button className={style.button} onClick={handleFileUpload}>Subir PDF</button>
    </div>
  );
}

export default MultimediaPdf;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tipos:[],
    tiposConMaquinas:[]
}

export const tiposSlice = createSlice({
    name: "Tipos",
    initialState,
    reducers: {
        guardarTipos: (state, action) => {
            state.tipos = action.payload
        },
        guardarTiposConMaquinas:(state,action)=>{
            state.tiposConMaquinas = action.payload
        }
      
    }
})

export const { guardarTipos, guardarTiposConMaquinas } = tiposSlice.actions;

export default tiposSlice.reducer;
import style from './css/HeaderContacto.module.css'


const HeaderContacto = () => {
  return (
    <div className={style.contenedor}>
        <h2 className={style.h2}>CONTACTANOS</h2>
    </div>
  )
}

export default HeaderContacto
import Busqueda from "./Busqueda";
import Vender from "./Vender";
import { Principal } from './Principal';
import { useEffect } from "react";
import { guardarTiposConMaquinas } from "../features/tiposSlice";
import { useDispatch, useSelector } from "react-redux";



const Home = () => {
  const url = useSelector(state => state.global.url)

  const dispatch = useDispatch();

  useEffect(() => {

    fetch(`${url}/maquinas/getMaquinasAndType`, {
      method: "GET",
    }).then(respuesta => respuesta.json())
      .then(datos => {
        
          dispatch(guardarTiposConMaquinas(datos.result.Tipos))
        
      }).catch(error => {
        console.error('Error en la solicitud:', error);
    });


  }, [])

  return (
    <div>
      <Principal />
      <Busqueda />
      <Vender />
    </div>
  )
}

export default Home